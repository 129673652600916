import React from 'react';
import './SubscriptionLandingpage.css';
import FaqSection from './faq';
import { logEvent } from 'firebase/analytics'; // Import logEvent
import { analytics, handleButtonClick } from '../../firebase'; // Ensure you import your analytics instance and the button click handler

const SubscriptionLandingpage = () => {
  // const upiId = 'vaidhyamegha.cca@icici';
  // const yearlyAmount = 96;
  // const name = 'VAIDHYAMEGHA PRIVATE LIMITED';

  // const createUpiLink = () => {
  //   return 'upi://pay?pa=vaidhyamegha.cca@icici';
  // };

  // function copyToClipboard() {
  //   const upiIdText = document.getElementById('upiId').innerText;
  //   navigator.clipboard.writeText(upiIdText).then(() => {
  //     // alert('UPI ID copied to clipboard');
  //   }).catch(err => {
  //     console.error('Could not copy text: ', err);
  //   });
  // }
  // Function to handle YouTube link click
  const handleYouTubeClick = () => {
    if (analytics) {
      logEvent(analytics, 'youtube_video_play', {
        content_type: 'video',
        item_id: 'BQZFJUj9jJc', // Video ID
      });
    }

    // Open the YouTube link
    window.open('https://www.youtube.com/watch?v=BQZFJUj9jJc', '_blank'); // Open the YouTube link

    if (analytics) {
      logEvent(analytics, 'youtube_link_open', {
        content_type: 'link',
        item_id: 'BQZFJUj9jJc', // Video ID
      });
    }
  };

  // Function to handle WhatsApp share button click
  const handleWhatsAppShare = () => {
    handleButtonClick('whatsapp_share_button'); // Log the WhatsApp share button click
    logEvent(analytics, 'whatsapp_share_click', {
      content_type: 'button',
      button_name: 'Share Screenshot via WhatsApp',
    });
    window.open("https://api.whatsapp.com/send?phone=+91 9618986039&text=I%20have%20made%20the%20payment.%20Please%20find%20the%20screenshot%20attached.", "_blank");
  };

  return (
    <div className="subscription-landingpage">
      <div className="subscription-landingpage-child" />
      <section className="content">
        <div className="frame-parent">
          <div className="workflow-content-parent">
            <div className="workflow-content">
              <div className="join-our-csdlc-container">
                <p className="join-our-csdlc-community-today">
                  <span className="join-our-csdlc-community-today1">
                    <span className="join-our-csdlc-community-today2">
                      <span className="join-our-csdlc">{`Join our CSDLC Community Today! `}</span>
                    </span>
                  </span>
                </p>
                <p className="blank-line">
                  <span>
                    <span className="blank-line1">
                      <span className="blank-line2">&nbsp;</span>
                    </span>
                  </span>
                </p>
                <p className="subscribe-for-just-800-rupe">
                  <span>
                    <span className="subscribe-for-just-800-rupe1">
                      <span className="subscribe-for-just">
                      Subscribe for just &#8377;96.00 per year and gain exclusive access to:
                      </span>
                    </span>
                  </span>
                </p>
                <p className="trends-and-knowledge-graphs-re">
                  <img
                    className="check-thick-check-form-valida-icon"
                    loading="lazy"
                    alt=""
                    src="/subscription/checkthickcheckformvalidationcheckmarksuccessaddaddition1tick.svg"
                  />
                  <span className="trends-and-knowledge-graphs-re1">
                    <span className="trends-and-knowledge">
                      Trends and knowledge graphs report twice a month.
                    </span>
                  </span>               
                </p>
                <p className="personalized-list-of-trials-th">
                  <img
                    className="check-thick-check-form-valida-icon1"
                    alt=""
                    src="/subscription/checkthickcheckformvalidationcheckmarksuccessaddaddition1tick-1.svg"
                  />
                  <span className="personalized-list-of-trials-th1">
                    <span className="personalized-list-of">{`Personalized list of trials that you may be eligible for. `}</span>
                  </span>
                </p>
                <p className="blank-line3">
                  <span>
                    <span className="blank-line4">
                      <span className="blank-line5">&nbsp;</span>
                    </span>
                  </span>
                </p>
                <p className="stay-informed-and-get-ahead-wi">
                  <span>
                    <span className="stay-informed-and-get-ahead-wi1">
                      <span className="stay-informed-and">
                        Stay informed and get ahead with our curated reports and
                        insights!
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div className="frame-group">
              <div className="frame-wrapper">
                <div className="image-1-parent">
                  <img
                    className="image-1-icon"
                    loading="lazy"
                    alt=""
                    src="/subscription/image-1@2x.png"
                  />
                  <div className="pay-via-qr">Pay via QR Code:</div>
                </div>
              </div>
              <div className="line-wrapper">
                <img
                  className="frame-child"
                  loading="lazy"
                  alt=""
                  src="/subscription/line-3.svg"
                />
              </div>
              {/* <div className="transfer-details-wrapper">
                <div className="transfer-details">
                  <p className='pay-using-upi-id-sl'>Pay using UPI ID</p>
                  <ul className="list-group list-group-flush text-center mb-4">
                    <li className="list-group-item">
                      <strong className='upi-id-text'>UPI ID:</strong> 
                      <span id="upiId" onClick={copyToClipboard} className="copyable">vaidhyamegha.cca@icici</span>
                      <button onClick={copyToClipboard} className="copy-button">Copy</button>
                    </li>
                  </ul>
                </div>
                <div className='pay-line'></div>
                <div className="subscription-buttons">
                  <h3 className='subscribe-via-upi-sl'>Subscribe via UPI:</h3>
                  <button className='payment-button-sl' onClick={() => window.open(createUpiLink(), '_blank')}>
                    <div className='payment-button-text-sl'> Yearly Subscription - ₹96</div>        
                  </button>
                </div>
              </div> */}
              <div className="frame-item" />
            </div>
          </div>
          <div className="once-you-have-made-the-payment-parent">
            <div className='once-you-have-container'>
              <div className="once-you-have">
                Once you have made the payment, take a screenshot of the payment
                confirmation page or receipt and share it with us via WhatsApp using the button below.
              </div>
            </div>           
            <div className="whatsapp-share">
              <button className='whatsapp-button-sl' onClick={handleWhatsAppShare}>
                <img
                  className="whatsapp-logo-icon"
                  loading="lazy"
                  alt=""
                  src="/subscription/whatsapp-logo-icon.svg"
                />
                <div className='whatsapp-button-text-sl'>Share Screenshot via WhatsApp</div> 
              </button>
            </div>
          </div>
        </div>
        <div className="research-insights-container">
          <div className='top-section'>
            <div className="section-header">
              <h2 className='unlock-the'>Unlock the Future of Clinical Research with CSDLC!</h2>
            </div>
            <div className="description-paragraph">
              <p>
                Stay ahead in the ever-evolving world of clinical trials. By subscribing to CSDLC, you gain exclusive access to in-depth reports, cutting-edge knowledge graphs, and personalized trial recommendations tailored just for you.
              </p>
            </div>
            <div className="description-paragraph">
              <p>
                Elevate your research, make informed decisions, and be part of a global community driving innovation in healthcare.
              </p>
            </div>
            <div className="description-paragraph">
              <p>
                Your journey towards deeper insights and impactful research starts here. Subscribe today and empower your clinical research like never before!
              </p>
            </div>
          </div>
          <div className='bottom-section'>
            <div className="video-section">
              <iframe 
                className='video-iframe'
                src="https://www.youtube.com/embed/BQZFJUj9jJc?playlist=BQZFJUj9jJc&loop=1&rel=0" 
                title="CSDLC Video" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
                onClick={handleYouTubeClick}
              />
            </div>
          </div>
        </div>
      </section>
      <FaqSection />
    </div>
  );
};

export default SubscriptionLandingpage;
