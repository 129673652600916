// src/components/FrameComponent.js
import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './FrameComponent.css';
import { getProfile } from '../services/apiService';
import { AuthContext } from '../context/AuthContext';

const FrameComponent = ({ className = '' }) => {
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const { isAuthenticated, logout, subscriberId } = useContext(AuthContext);

  useEffect(() => {
    console.log('FrameComponent: isAuthenticated:', isAuthenticated);
    console.log('FrameComponent: Subscriber ID:', subscriberId);
  }, [isAuthenticated, subscriberId]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleProfileNavigation = () => {
    getProfile(subscriberId)
      .then((response) => {
        const profileExists =
          response.data &&
          response.data.demographics &&
          Object.values(response.data.demographics).some((field) => field !== null);

        if (profileExists) {
          navigate('/profile'); // Navigate to profile (readonly)
        } else {
          navigate('/profile-update'); // Navigate to profile update (editable)
        }
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        navigate('/profile-update'); // Navigate to profile update if there's an error or no profile
      });
  };

  const handleLogout = () => {
    logout(navigate); // Pass navigate to logout for redirection
  };

  return (
    <div className={`rectangle-group ${className}`}>
      <img className="final-logo-csdlc-1" loading="lazy" alt="" src="/subscription/final-logo-csdlc-1.svg" />
      <div className="button-and-usermenu">
        <button className="visit-dashboard-button" onClick={() => navigate('/')}>
          VISIT DASHBOARD
        </button>
        <div className="user-menu" onClick={toggleDropdown} ref={dropdownRef}>
          <div className="user-icon-wrapper">
            <img className="user-icon" loading="lazy" alt="" src="/subscription/user-icon.svg" />
            <img className="vector-icon3" alt="" src="/subscription/vector-3.svg" />
          </div>
          {dropdownVisible && (
            <div className="dropdown-content">
              {!isAuthenticated ? (
                <div className="dropdown-item" onClick={() => navigate('/login')}>
                  <div className="dropdown-text">LOGIN</div>
                </div>
              ) : (
                <>
                  <div className="dropdown-item" onClick={handleProfileNavigation}>
                    <img src="/layout/settings-icon.png" alt="Settings" className="dropdown-icon" />
                    <div className="dropdown-text">MY PROFILE</div>
                  </div>
                  <div className="dropdown-item" onClick={() => navigate('/change-password')}>
                    <img src="/layout/change-password-icon.png" alt="Change Password" className="dropdown-icon" />
                    <div className="dropdown-text">CHANGE PASSWORD</div>
                  </div>
                  <div className="dropdown-item" onClick={handleLogout}>
                    <img src="/layout/logout-icon.png" alt="Logout" className="dropdown-icon" />
                    <div className="dropdown-text">LOGOUT</div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FrameComponent;
