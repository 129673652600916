// src/pages/login/login.js
import { useCallback, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../services/authService";
import { getProfile } from "../../services/apiService"; 
import { AuthContext } from '../../context/AuthContext';
import "./login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleLogin = useCallback(() => {
    if (!email || !password) {
        setError("Please enter both email and password.");
        return;
    }

    setIsLoggingIn(true);  // Start loading

    loginApi(email, password)
        .then(({ token, subscriber_id }) => {
            login(token, subscriber_id);
            getProfile(subscriber_id)
                .then((response) => {
                    if (response.data) {
                        navigate("/profile");
                    } else {
                        navigate("/profile-update");
                    }
                })
                .catch(() => navigate("/profile-update"));
        })
        .catch(err => setError(err.message || "An error occurred during login."))
        .finally(() => setIsLoggingIn(false));  // Reset loading
  }, [email, password, login, navigate]);

  return (
    <div className="login">
      <div className="login-content">
        <div className="welcome-back-please-container">
          <p className="welcome-back">
            <span>
              <span className="welcome-back1">Welcome back!</span>
            </span>
          </p>
          <p className="blank-line">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="please-log-in-to-access-your-s">
            <span>
              <span>
                Please log in to access your subscription benefits, including
                trends and knowledge graphs reports and your personalized list of
                trials.
              </span>
            </span>
          </p>
        </div>
        <div className="form">
          <div className="email">
            <div className="email-id-l">Email ID :</div>
            <input
              type="text"
              className="email-child"
              placeholder="Enter your Email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="password">
            <div className="password1">Password :</div>
            <input
              type="password"
              className="password-child"
              placeholder="Enter your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>         
          <div className="search-div">
            <div className="login-div">
              <div className="search" onClick={isLoggingIn ? null : handleLogin}>
                  <b className="login1">{isLoggingIn ? 'Logging in...' : 'Login'}</b>
              </div>
              <div className="error-div">
                  {error && <div className="error">{error}</div>}
              </div>
            </div>           
            <div className='password-div'>
              <div className="forgot-password1">
                <a href="/forgot-password">Forgot Password?</a>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
