
export const filterProfileData = (data) => {
  return {
    fullName: data.demographics.full_name || '',
    age: data.demographics.age || '',
    gender: data.demographics.gender || '',
    country: data.demographics.country || '',
    address: data.demographics.address || '',
    pincode: data.demographics.pincode || '',
    aadhaar: data.demographics.aadhaar_id || '',
    abha: data.demographics.abha_id || '',
    email: data.demographics.email || '',
    phone: data.demographics.phone || '',
    medicalConditions: data.medical_history.medical_conditions || '',
    medications: data.medical_history.medications || '',
    allergies: data.medical_history.allergies || '',
    surgeries: data.medical_history.previous_surgeries_procedures || '',
    familyHistory: data.medical_history.family_medical_history || '',
    dietaryHabits: data.lifestyle.dietary_habits || '',
    exerciseFrequency: data.lifestyle.exercise_frequency || '',
    smokingStatus: data.lifestyle.smoking_status || '',
    alcoholConsumption: data.lifestyle.alcohol_consumption || '',
    substanceUse: data.lifestyle.substance_use || '',
    recentLabResults: data.clinical_data.recent_lab_results || '',
    diagnosticReports: data.clinical_data.diagnostic_reports || '',
    height: data.clinical_data.height_cm || '',
    weight: data.clinical_data.weight_kg || '',
    bmi: data.clinical_data.bmi || '',
    diagnosticReportsSignedUrl: data.clinical_data.diagnosticReportsSignedUrl || '',
    participationAvailability: data.additional_info.participation_availability || '',
    agree: data.additional_info.consent_to_use_data || false
  };
};
