import React, { useState } from "react";
import { forgotPasswordApi } from "../../services/authService";
import "./forgot-password.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleForgotPassword = () => {
    if (!email) {
      setError("Please enter your email.");
      return;
    }
  
    forgotPasswordApi(email)
      .then((res) => {
        setMessage("Password reset link has been sent to your email.");
        setError("");
      })
      .catch((err) => {
        setError(err.message || "Error triggering forgot password. Please try again.");
      });
  };

  return (
    <div className="forgot-password">
      <div className="forgot-password-inner">
        <h2 className="forgot-password-title">Forgot Password</h2>
        {message ? (
          <div className="confirmation-message">
            <p>
              Password reset link has been sent to your{" "}
              <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                email
              </a>.
            </p>
            <p>
              If you don't see the email, please check your spam folder or{" "}
              <a href="/forgot-password">try requesting a new one</a>.
            </p>
          </div>
        ) : (
          <>
            <div className="form-group-fp">
              <label className="fp-label" htmlFor="email">
                Email<span className="required-field">*</span>:
              </label>
              <input
                className="email-input-fp"
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-buttons-fp">
              <button className="submit-button-fp" onClick={handleForgotPassword}>
                <b className="submit-text-fp">SUBMIT</b>
              </button>
            </div>
            {error && <div className="error-fp">{error}</div>}
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
