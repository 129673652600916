// src/pages/change-password/reset-password.js

import React, { useState, useEffect } from "react";
import { resetPasswordApi } from "../../services/authService"; 
import { useLocation, useNavigate } from "react-router-dom";
import "./change-password.css"; // Use the same CSS file to maintain consistency

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [new_password, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isTokenExpired, setIsTokenExpired] = useState(false); // To show token expiration message

  // Get the reset token from the URL query parameter
  const params = new URLSearchParams(location.search);
  const reset_token = params.get('token');

  useEffect(() => {
    if (!reset_token) {
      setError("Invalid password reset link.");
      console.error("No reset token found in the URL.");
    }
  }, [reset_token]);

  const handleResetPassword = () => {
    if (!new_password || !confirmPassword) {
      setError("Please fill all the required fields.");
      return;
    }

    if (new_password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Call resetPasswordApi with the reset token and new password
    resetPasswordApi(reset_token, new_password)
      .then(() => {
        setSuccess("Password reset successfully.");
        setError("");
        setTimeout(() => navigate("/login"), 2000); // Redirect to login after success
      })
      .catch((err) => {
        if (err.response?.status === 401 && err.response?.data?.error === "Token has expired") {
          setError("The password reset link has expired. Please request a new reset link.");
          setIsTokenExpired(true); // Indicate token expiration
        } else {
          setError(err.message || "Error resetting password. Please try again.");
        }
        console.error("Error resetting password:", err.message || err);
      });
  };

  return (
    <div className="change-password"> {/* Use the same container class */}
      <div className="change-password-inner"> {/* Use the same inner container class */}
        <h2>Reset Password</h2>
        {error ? (
          <div className="error">{error}</div>
        ) : (
          <>
            <div className="form-fields-cp"> {/* Use the same form fields container class */}
              <div className="form-group-cp"> {/* Use the same form group class */}
                <label className="cp-label" htmlFor="new_password">
                  New Password<span className="required-field">*</span>:
                </label>
                <input
                  className="new-password-cp"
                  type="password"
                  name="new_password"
                  id="new_password"
                  value={new_password}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  disabled={isTokenExpired} // Disable the field if the token has expired
                />
              </div>

              <div className="form-group-cp">
                <label className="cp-label" htmlFor="confirmPassword">
                  Confirm New Password<span className="required-field">*</span>:
                </label>
                <input
                  className="new-password-cp"
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  disabled={isTokenExpired} // Disable the field if the token has expired
                />
              </div>
            </div>
            
            <div className="form-buttons form-buttons-cp"> {/* Use the same form buttons classes */}
              <button 
                className="submit-button-cp" 
                onClick={handleResetPassword} 
                disabled={isTokenExpired} // Disable button if the token has expired
              >
                SUBMIT
              </button>
            </div>

            {success && <div className="success">{success}</div>}
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
