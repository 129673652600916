// src/context/AuthContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { getCookie, setCookie, removeCookie } from '../services/cookieService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [subscriberId, setSubscriberId] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Check if token exists on component mount
    const token = getCookie('token');
    const subscriberId = getCookie('subscriber_id');

    console.log('AuthContext: Checking cookies on mount. Token:', token, 'Subscriber ID:', subscriberId);

    if (token && subscriberId) {
      setToken(token);
      setSubscriberId(subscriberId);
      setIsAuthenticated(true);
      console.log('AuthContext: User is authenticated');
    } else {
      setIsAuthenticated(false);
      console.log('AuthContext: User is not authenticated');
    }
  }, []);

  const login = (token, subscriberId) => {
    setCookie('token', token);
    setCookie('subscriber_id', subscriberId);
    console.log('AuthContext: Token set in cookie:', token); // Log the token
    setToken(token);
    setSubscriberId(subscriberId);
    setIsAuthenticated(true);
  };

  const logout = useCallback(() => {
    // Remove cookies
    removeCookie('token');
    removeCookie('subscriber_id');

    setToken(null);
    setSubscriberId(null);
    setIsAuthenticated(false);

    console.log('AuthContext: User logged out');
  }, []);

  useEffect(() => {
    // Set up Axios interceptors here
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        config.headers['X-allowed'] = 'true';

        const excludedRoutes = ['/login', '/forgot-password', '/reset-password'];
        const shouldExclude = excludedRoutes.some((route) => config.url.includes(route));

        if (!shouldExclude && token) {
          config.headers.Authorization = `Bearer ${token}`;
          console.log('AuthContext: Adding Authorization header with token:', token);
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response?.status;
        const errorMessage = error.response?.data?.error || error.message;

        if (status === 401) {
          console.log('AuthContext: 401 Unauthorized detected:', errorMessage);

          if (errorMessage === "Token has expired") {
            console.log('AuthContext: Token expired. Showing message before logging out...');
            // Delay the logout by 3 seconds, giving time to show the session expiration message
            setTimeout(() => {
              logout();
            }, 3000); // 3 seconds delay before logging out
          } else {
            console.log('AuthContext: Unauthorized but token is not expired.');
          }
        }

        return Promise.reject(error);
      }
    );

    // Cleanup interceptors on unmount
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [token, logout]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        subscriberId,
        token,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
