// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Layout from './components/layout';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';

// Import your pages
import SubscriptionLandingpage from './pages/subscription/SubscriptionLandingpage';
import Login from './pages/login/login';
import ChangePassword from './pages/change-password/change-password';
import ResetPassword from './pages/change-password/reset-password';
import ForgotPassword from './pages/forgot-password/forgot-password';
import ProfileUpdate from './pages/profile-update/profile-update';
import ThankYouPage from './pages/thankyou/ThankYouPage';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider> {/* Wrap the app with AuthProvider to access authentication state */}
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<SubscriptionLandingpage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/thankyou" element={<ThankYouPage />} />
              <Route
                path="/profile-update"
                element={
                  <PrivateRoute>
                    <ProfileUpdate isReadOnly={false} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <ProfileUpdate isReadOnly={true} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/change-password"
                element={
                  <PrivateRoute>
                    <ChangePassword />
                  </PrivateRoute>
                }
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              {/* Add other routes here as needed */}
            </Routes>
          </Layout>
        </Router>
      </AuthProvider>
    </ErrorBoundary>
    
  );
}

export default App;
