import React, { useState, useContext } from "react";
import { changePasswordApi } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext"; 
import "./change-password.css";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext); 
  const [current_password, setCurrentPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handlePasswordChange = (e) => {
    e.preventDefault(); 

    if (!current_password || !new_password || !confirmPassword) {
      setError("Please fill all the required fields.");
      return;
    }

    if (new_password !== confirmPassword) {
      setError("Passwords do not match.");
      setNewPassword("");
      setConfirmPassword("");
      return;
    }

    changePasswordApi(current_password, new_password)
      .then(() => {
        setSuccess("Password changed successfully.");
        setError("");

        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");

        setTimeout(() => navigate("/profile"), 2000);
      })
      .catch((err) => {
        const status = err.response?.status;
        const errorMessage = err.response?.data?.error || err.message;

        if (status === 401) {
          if (errorMessage === "Token has expired") {
            setError("Session expired, please log in again.");
            setTimeout(() => {
              logout(); // Log out after 3 seconds
              navigate("/login");
            }, 3000); // 3-second delay before navigating to login
          } else if (errorMessage === "Current password is incorrect") {
            setError("The current password is incorrect. Please try again.");
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
          }
        } else {
          setError(errorMessage || "Error changing password. Please try again.");
          setNewPassword("");
          setConfirmPassword("");
        }
      });
  };

  return (
    <div className="change-password">
      <div className="change-password-inner">
        <h2>Change Password</h2>

        <div className="form-fields-cp">
          <div className="form-group-cp">
            <label className="cp-label" htmlFor="current_password">
              Current Password<span className="required-field">*</span>:
            </label>
            <input
              className="current-password-cp"
              type="password"
              name="current_password"
              id="current_password"
              value={current_password}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
              autoComplete="off" // Prevent browser password prompt
            />
          </div>

          <div className="form-group-cp">
            <label className="cp-label" htmlFor="new_password">
              New Password<span className="required-field">*</span>:
            </label>
            <input
              className="new-password-cp"
              type="password"
              name="new_password"
              id="new_password"
              value={new_password}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              autoComplete="off" // Prevent browser password prompt
            />
          </div>

          <div className="form-group-cp">
            <label className="cp-label" htmlFor="confirmPassword">
              Confirm New Password<span className="required-field">*</span>:
            </label>
            <input
              className="new-password-cp"
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              autoComplete="off" // Prevent browser password prompt
            />
          </div>
        </div>

        <div className="form-buttons form-buttons-cp">
          <button className="submit-button-cp" onClick={handlePasswordChange}>
            Save Changes
          </button>
        </div>

        {success && <div className="success">{success}</div>}
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
};

export default ChangePassword;
