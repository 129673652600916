// src/services/authService.js

import axiosInstance from './axiosConfig';

// Mock API for login
// export const mockLoginApi = async (email, password) => {
//   // Simulate a successful login response
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         data: {
//           token: 'mocked_token',
//         },
//         headers: {
//           'x-subscriber-id': 1001, // Hardcoded subscriber ID
//         },
//       });
//     }, 1000); // Simulate network delay
//   });
// };

// Function to handle login and return token and subscriber_id
export const loginApi = async (email, password) => {
  try {
    const response = await axiosInstance.post('https://gateway.fs.vaidhyamegha.com/login', { email, password });
    const token = response.data.token;
    const subscriber_id = response.headers['x-subscriber-id'];
    return { token, subscriber_id };
  } catch (error) {
    console.error('Login failed:', error.message || error);
    throw new Error('Invalid email or password');
  }
};

// Forgot Password API
export const forgotPasswordApi = async (email) => {
  try {
    const response = await axiosInstance.post('https://gateway.fs.vaidhyamegha.com/forgot-password', { email });
    return response.data.message;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to trigger forgot password email.');
  }
};

// Reset password with the token (extracted from the URL)
export const resetPasswordApi = async (reset_token, new_password) => {
  try {
    const response = await axiosInstance.post('https://gateway.fs.vaidhyamegha.com/reset-password', {
      token: reset_token, // Token from the URL (not the JWT token from login)
      new_password,
    });
    return response.data.message;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to reset password.');
  }
};

// Change password API (when logged in)
export const changePasswordApi = async (current_password, new_password) => {
  try {
    const response = await axiosInstance.post('https://gateway.fs.vaidhyamegha.com/change-password', {
      current_password,
      new_password,
    });
    return response.data.message;
  } catch (error) {
    console.error('Error in changePasswordApi:', error.response || error.message || error);
    throw error; // Let the calling function (in ChangePassword.js) handle UI logic
  }
};

