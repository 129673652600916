import React, { useRef, useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import './ProfileUpdate.css';
import './diagnosticsreport.css';
import { medicalConditions, dietaryHabits, exerciseFrequency, smokingStatus, alcoholConsumption, participationAvailability } from './dropdownData'; // Import dropdown data
import { filterProfileData } from './filterProfileData'; // Import profile utilities
import { createProfile, updateProfile, getProfile } from '../../services/apiService'; // Import API services
// import { useNavigate } from 'react-router-dom'; // Import hooks
import { countries } from 'country-data';
import resetFormData from './resetFormData';
// import { getSubscriberIdFromCookie } from '../../services/authService';
import { AuthContext } from '../../context/AuthContext';
import { saveFormDataToBackend } from '../../services/apiService';
import { getSavedFormData } from '../../services/apiService';
import { deleteSavedFormData } from '../../services/apiService';
import debounce from 'lodash.debounce';


const Dropdown = ({ label, name, options, required, value, onChange, isEditable }) => (
  <div className="form-group">
    <label 
      className="form-label" 
      htmlFor={name}>{label}<span className="required-field">{required ? '*' : ''}</span>:
    </label>
    <select 
      className={`form-select ${isEditable ? 'dropdown-arrow' : 'readonly-dropdown'}`} 
      name={name} id={name} 
      required={required} 
      value={value} 
      onChange={onChange} 
      disabled={!isEditable}
    >
      <option value="">Select {label}</option>
      {options.map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ))}
    </select>
  </div>
);

const ProfileUpdate = () => {
  const [formData, setFormData] = useState(resetFormData());
  const [isNewProfile, setIsNewProfile] = useState(true);
  const [isEditable, setIsEditable] = useState(false); // State to toggle between read-only and editable
  const [notification, setNotification] = useState('');
  const [notificationType, setNotificationType] = useState('');
  // const navigate = useNavigate();
  // const location = useLocation();
  const demographicRef = useRef(null);
  const medicalHistoryRef = useRef(null);
  const lifestyleRef = useRef(null);
  const clinicalDataRef = useRef(null);
  const additionalInfoRef = useRef(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { subscriberId } = useContext(AuthContext);

  // Memoize the onDrop function
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]; // Handle only the first file
    if (file) {
      setFormData(prevFormData => ({
        ...prevFormData,
        diagnosticReports: file
      }));
    }
  }, []);

  // Memoize the options for useDropzone
  const dropzoneOptions = useMemo(() => ({
    onDrop,
    disabled: !isEditable
  }), [onDrop, isEditable]);

  // Initialize useDropzone with memoized options
  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);


  useEffect(() => {
    // Log the subscriber ID whenever the component is mounted or subscriberId changes
    console.log('Navigating to ProfileUpdate screen');
    console.log('Subscriber ID:', subscriberId);
    
    // You can also conditionally handle when subscriberId is missing or undefined
    if (!subscriberId) {
      console.warn('No subscriber ID found. Please check if the user is authenticated.');
    }
  }, [subscriberId]);

  useEffect(() => {
    const fetchData = async () => {
      if (!subscriberId) {
        console.warn('No subscriber ID found. Please check if the user is authenticated.');
        return;
      }
  
      // Add a slight delay to ensure the token is set
      await new Promise(resolve => setTimeout(resolve, 100)); // 100ms delay
  
      try {
        // Step 1: Try to fetch saved form data from Redis
        const savedFormDataResponse = await getSavedFormData(subscriberId);
        const savedFormData = savedFormDataResponse.data?.formData;
  
        if (savedFormData && Object.keys(savedFormData).length > 0) {
          console.log('Found saved form data in Redis:', savedFormData);
          setFormData(prevFormData => ({
            ...prevFormData,
            ...savedFormData,
          }));
          
          // Ensure that formData is set correctly for edit mode if data is found in Redis
          setIsEditable(true); // User has unsaved changes, so allow editing
          setIsNewProfile(false);
          setIsDataLoaded(true);
          return; // Exit the function; no need to fetch from the database
        }
  
        // Step 2: No saved form data, fetch profile from the database
        const profileResponse = await getProfile(subscriberId);
        const profileData = profileResponse.data;
  
        if (profileData && Object.keys(profileData).length > 0) {
          console.log('Profile data fetched from database:', profileData);
  
          const clinicalData = profileData.clinical_data || {};
          const diagnosticReportsSignedUrl = clinicalData.diagnosticReportsSignedUrl || '';
  
          setFormData(prevFormData => ({
            ...prevFormData,
            ...filterProfileData(profileData),
            diagnosticReportsSignedUrl: diagnosticReportsSignedUrl,
            diagnosticReports: clinicalData.diagnostic_reports || '',
          }));
  
          setIsEditable(false); // Start in read-only mode since we fetched data from DB
          setIsNewProfile(false);
          setIsDataLoaded(true);
        } else {
          // Profile data is empty
          throw new Error('Empty profile data');
        }
      } catch (error) {
        const status = error.response?.status;
        const errorMessage = error.response?.data?.error || error.message;
  
        if (status === 404 || errorMessage === 'Empty profile data') {
          // Profile doesn't exist in database, set up for new profile
          setFormData(resetFormData());
          setIsNewProfile(true);
          setIsEditable(true); // Allow user to enter new profile data
          setIsDataLoaded(true);
        } else {
          console.error('Error fetching data:', error);
          setNotification('An error occurred while fetching your profile. Please try again.');
          setNotificationType('error');
          setIsDataLoaded(false);
        }
      }
    };
  
    fetchData();
  }, [subscriberId]);

  
  useEffect(() => {
    const autosave = debounce(async () => {
      if (isEditable) {
        try {
          await saveFormDataToBackend(formData, subscriberId);
          console.log('Form data autosaved');
        } catch (error) {
          console.error('Autosave failed:', error);
        }
      }
    }, 5000); // Autosave every 10 seconds of inactivity
  
    autosave();
  
    return () => {
      autosave.cancel();
    };
  }, [formData, isEditable, subscriberId]);  

  useEffect(() => {
    if (!isEditable) {
        const formSections = [demographicRef, medicalHistoryRef, lifestyleRef, clinicalDataRef, additionalInfoRef];
        formSections.forEach(sectionRef => {
            const formElement = sectionRef.current;
            if (formElement) {
                formElement.classList.add('readonly-style');
            }
        });
    }
  }, [isEditable]);
  
  // useEffect(() => {
  //   if (!isNewProfile) {
  //       navigate('/profile');
  //   }
  // }, [isNewProfile, navigate]);


  // Simplified handleEdit function since the profile data is already fetched
  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditable(true);
    setNotification('');
  };
  
  

  // Calculate BMI whenever height or weight changes
  useEffect(() => {
    const calculateBMI = () => {
      if (formData.height && formData.weight) {
        const heightInMeters = formData.height / 100;
        const bmi = (formData.weight / (heightInMeters * heightInMeters)).toFixed(2);
        setFormData(prevFormData => ({ ...prevFormData, bmi }));
      }
    };
    calculateBMI();
  }, [formData.height, formData.weight]);

  useEffect(() => {
    if (isDataLoaded && isEditable) {
  
    }
  }, [isDataLoaded, isEditable]);  

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value || '' // Ensuring value is never undefined
    });
  };

// const handleSave = (sectionRef) => async (e) => {
//     e.preventDefault();
//     const inputs = sectionRef.current.querySelectorAll('input, select');
//     const updatedFields = {};

//     let isValid = true;
//     inputs.forEach(input => {
//       if (input.required && !input.value) {
//         isValid = false;
//       }
//       updatedFields[input.name] = input.type === 'checkbox' ? input.checked : input.value;
//     });

//     if (isValid) {
//       const newFormData = { ...formData, ...updatedFields };
//       setFormData(newFormData);

//       try {
//         // Send form data to backend to save
//         await saveFormDataToBackend(newFormData, subscriberId);
//         showNotification('Profile information saved!');
//       } catch (error) {
//         showNotification('Failed to save data. Please try again.', 'error');
//       }
//     } else {
//       showNotification('Please fill in all required fields in this section.', 'error');
//     }
// };

  // const clearSection = (sectionRef) => {
  //   if (sectionRef.current) {
  //     const inputs = sectionRef.current.querySelectorAll('input, select');
  //     const updatedFields = {};
  
  //     inputs.forEach(input => {
  //       if (input.type === 'checkbox') {
  //         updatedFields[input.name] = false;
  //       } else {
  //         updatedFields[input.name] = '';
  //       }
  //     });
  
  //     const updatedFormData = { ...formData, ...updatedFields };
  //     setFormData(updatedFormData);
  //     // localStorage.setItem('profileData', JSON.stringify(updatedFormData));
  //   }
  // };
	

//   const showNotification = (message, type = 'success') => {
//     setNotification(message);
//     setNotificationType(type);

//     // Clear previous timeout before setting a new one
//     const timer = setTimeout(() => {
//         setNotification('');
//         setNotificationType('');
//     }, 3000);  // Hide after 5 seconds

//     // Cleanup function to clear timeout if the component unmounts
//     return () => clearTimeout(timer);
// } ;

const getFileNameWithoutTimestamp = (urlOrPath) => {
  if (!urlOrPath || typeof urlOrPath !== 'string') {
    return 'Unknown file';
  }
  try {
    const fileNameWithTimestamp = urlOrPath.substring(urlOrPath.lastIndexOf('/') + 1).split('?')[0];
    const fileName = fileNameWithTimestamp.replace(/^\d+_/, '');
    return decodeURIComponent(fileName);
  } catch (error) {
    console.error('Error parsing file name:', error);
    return 'Unknown file';
  }
};

const handleSubmit = async (e) => {
  e.preventDefault();
  if (!isEditable || isSubmitting) return; // Prevent multiple submissions

  setIsSubmitting(true);  // Show loading state

  const formDataToSend = new FormData();

  // Append form data
  for (const [key, value] of Object.entries(formData)) {
    if (key === 'diagnosticReports' && value instanceof File) {
      formDataToSend.append(key, value);
    } else {
      formDataToSend.append(key, value);
    }
  }
  formDataToSend.append('subscriber_id', subscriberId);

  const apiCall = isNewProfile ? createProfile : (data) => updateProfile(subscriberId, data);

  try {
    await apiCall(formDataToSend);  // Remove 'response' variable
    setNotification(`Profile ${isNewProfile ? 'created' : 'updated'} successfully!`, 'success');

      // Clear saved form data from Redis
      try {
    await deleteSavedFormData(subscriberId);
        console.log('Cleared saved form data from Redis after submission');
      } catch (error) {
        console.error('Failed to clear saved form data from Redis:', error);
      }

      // Set form to read-only mode
    setIsEditable(false);
    setIsSubmitting(false);
    setIsNewProfile(false);

    // Fetch the updated profile data
    const updatedProfileResponse = await getProfile(subscriberId);
    const updatedProfileData = updatedProfileResponse.data;

    setFormData(prevFormData => ({
      ...prevFormData,
      ...filterProfileData(updatedProfileData),
      diagnosticReportsSignedUrl: updatedProfileData.clinical_data?.diagnosticReportsSignedUrl || '',
      diagnosticReports: updatedProfileData.clinical_data?.diagnostic_reports || '',
    }));

  } catch (error) {
    console.error(`Failed to ${isNewProfile ? 'create' : 'update'} profile:`, error);
    setNotification(`Failed to ${isNewProfile ? 'create' : 'update'} profile`, 'error');
    setIsSubmitting(false);
  }
};

  useEffect(() => {
    if (notification) {
        const timer = setTimeout(() => {
            setNotification('');
            setNotificationType('');
        }, 5000);

        return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }
  }, [notification, notificationType]);

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];  // Get the first file selected
  //   if (file) {
  //       setFormData(prevFormData => ({
  //           ...prevFormData,
  //           diagnosticReports: file
  //       }));
  //   }
  // };

  // Add console statements to debug
  useEffect(() => {
    console.log('isEditable:', isEditable);
    console.log('formData.diagnosticReports:', formData.diagnosticReports);
    console.log('formData.diagnosticReportsSignedUrl:', formData.diagnosticReportsSignedUrl);
  }, [isEditable, formData.diagnosticReports, formData.diagnosticReportsSignedUrl]);  

  // Use country-data to get all country names and sort them alphabetically
  const countryOptions = countries.all.map(country => country.name).sort();

  const handleCancel = async () => {
    try {
      await deleteSavedFormData(subscriberId);
      const profileResponse = await getProfile(subscriberId);
      const profileData = profileResponse.data;
      
      if (profileData && Object.keys(profileData).length > 0) {
        setFormData(prevFormData => ({
          ...prevFormData,
          ...filterProfileData(profileData),
          diagnosticReportsSignedUrl: profileData.clinical_data?.diagnosticReportsSignedUrl || '',
          diagnosticReports: profileData.clinical_data?.diagnostic_reports || '',
        }));
        setIsEditable(false);
      } else {
        setFormData(resetFormData());
        setIsNewProfile(true);
        setIsEditable(true);
      }
    } catch (error) {
      console.error('Error cancelling changes:', error);
      setNotification('Failed to cancel changes. Please try again.', 'error');
    }
  };

  return (
    <div className="profile-update-container">
      {!isDataLoaded ? (
      <div className='unable-to-load'>
        <div className='unable-to-load-text'>
          Unable to load profile data. Please try again later.
        </div>  
      </div>
      ) : (
      <>
        <div className="profile-update-header">
          <h2 className="profile-update-title">Please update your profile information</h2>
          <p className="profile-update-subtitle">
            Enhance Your Clinical Trial Matches with Detailed Demographic Data
          </p>
        </div>
        <div className='please-update-div'>
          <div className="profile-update-description">
            Please provide your detailed demographic, medical, lifestyle, and clinical information to help us generate more precise and personalized clinical trial reports for you. Your updated profile will enable us to match you with trials that best fit your health status and preferences. Your data will be securely stored and used solely for research and matching purposes.
          </div>
          <div className='profile-update-image-div'>
            <img className='profile-update-image1' src='/profile-update/book.png' alt='book' />
          </div>
        </div>
        {notification && (
          <div className={`notification ${notificationType}`}>
            {notification}
          </div>
        )}
        <form className="profile-update-form" onSubmit={handleSubmit}>
          {/* Demographic Information Section */}
          <div className="form-section" ref={demographicRef}>
          <div className="section-title-container">
            <h3 className="section-title">Demographic Information</h3>
          </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="fullName">Full Name<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input" 
                type="text" 
                name="fullName" 
                id="fullName" 
                required value={formData.fullName || ''} 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="age">Age<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input small-input" 
                type="number" 
                name="age" 
                id="age" 
                required value={formData.age || ''} 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
              <span className="unit-label">Years</span>
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="gender">Gender<span className="required-field">*</span>:
              </label>
              <select 
                className="form-select dropdown-arrow small-input" 
                name="gender" 
                id="gender" 
                required value={formData.gender || ''} 
                onChange={handleChange} 
                disabled={!isEditable}>
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="country">Country<span className="required-field">*</span>:
              </label>
              <select 
                className="form-select dropdown-arrow small-input" 
                name="country" 
                id="country" 
                required value={formData.country || ''} 
                onChange={handleChange} 
                disabled={!isEditable}
              >
                <option value="">Select Country</option>
                {countryOptions.map((country, index) => (
                  <option key={index} value={country}>{country}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="address">Address<span 
                className="required-field">*</span>:
              </label>
              <input 
                className="form-input" 
                type="text" 
                name="address" 
                id="address" 
                required value={formData.address || ''} 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="pincode">Pincode<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input small-input" 
                type="text" 
                name="pincode" 
                id="pincode" 
                required value={formData.pincode || ''} 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="aadhaar">Aadhaar ID<span 
                className="required-field">*</span>:
              </label>
              <input 
                className="form-input" 
                type="text" 
                name="aadhaar" 
                id="aadhaar" 
                required value={formData.aadhaar || ''} 
                onChange={handleChange} 
                maxLength="12" 
                readOnly={!isEditable} 
              />
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="abha">ABHA ID:
              </label>
              <input 
                className="form-input" 
                type="text" 
                name="abha" 
                id="abha" 
                value={formData.abha || ''} 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="email">Email<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input" 
                type="email" 
                name="email" 
                id="email" 
                required value={formData.email || ''} 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="phone">Phone<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input" 
                type="tel" 
                name="phone" 
                id="phone" 
                required 
                value={formData.phone || ''} 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
            </div>
            {/* <div className="form-buttons">
              <button 
                  type="button" 
                  className={`cancel-button demographic-cancel-button ${!isEditable ? 'disabled-button' : ''}`} 
                  onClick={() => isEditable && clearSection(demographicRef)}
                  disabled={!isEditable}
              >
                  Cancel
              </button>
              <button 
                  type="button" 
                  className={`save-button demographic-save-button ${!isEditable ? 'disabled-button' : ''}`} 
                  onClick={handleSave(demographicRef)}
                  disabled={!isEditable}
              >
                  Save
              </button>
            </div> */}
          </div>

          {/* Medical History Section */}
          <div className="form-section" ref={medicalHistoryRef}>
            <div className="section-title-container">
              <h3 className="section-title">Medical History</h3>
            </div>
            <Dropdown 
              label="Medical Conditions" 
              name="medicalConditions" 
              options={medicalConditions} 
              required 
              value={formData.medicalConditions || ''} 
              onChange={handleChange} 
              isEditable={isEditable} 
            />
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="medications">Medications<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input" 
                type="text" 
                name="medications" 
                id="medications" 
                required 
                value={formData.medications || ''} 
                placeholder='Write your medicine names here. (e.g., Lisinopril)' 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="allergies">Allergies<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input" 
                type="text" 
                name="allergies" 
                id="allergies" 
                required 
                value={formData.allergies || ''} 
                placeholder='Write your allergies here. (e.g., Peanuts)' 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="surgeries">Previous Surgeries/Procedures<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input" 
                type="text" 
                name="surgeries" 
                id="surgeries" 
                required 
                value={formData.surgeries || ''} 
                placeholder='Mention if you had previous surgeries or procedures' 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="familyHistory">Family Medical History<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input" 
                type="text" 
                name="familyHistory" 
                id="familyHistory" 
                required 
                value={formData.familyHistory || ''} 
                placeholder='Relevant family health history (e.g., heart disease, cancer)' 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
            </div>
            {/* <div className="form-buttons">
              <button 
                  type="button" 
                  className={`cancel-button medicalhistory-cancel-button ${!isEditable ? 'disabled-button' : ''}`} 
                  onClick={() => isEditable && clearSection(medicalHistoryRef)}
                  disabled={!isEditable}
              >
                  CANCEL
              </button>
              <button 
                  type="button" 
                  className={`save-button medicalhistory-save-button ${!isEditable ? 'disabled-button' : ''}`} 
                  onClick={handleSave(medicalHistoryRef)}
                  disabled={!isEditable}
              >
                  SAVE
              </button>
            </div> */}

          </div>
  
          {/* Lifestyle Information Section */}
          <div className="form-section" ref={lifestyleRef}>
            <div className="section-title-container">
              <h3 className="section-title">Lifestyle Information</h3>
            </div>
            <Dropdown 
              label="Dietary Habits" 
              name="dietaryHabits" 
              options={dietaryHabits} 
              required 
              value={formData.dietaryHabits || ''} 
              onChange={handleChange} 
              isEditable={isEditable} 
            />
            <Dropdown 
              label="Exercise Frequency" 
              name="exerciseFrequency" 
              options={exerciseFrequency} 
              required 
              value={formData.exerciseFrequency || ''} 
              onChange={handleChange} 
              isEditable={isEditable} 
            />
            <Dropdown 
              label="Smoking Status" 
              name="smokingStatus" 
              options={smokingStatus} 
              required 
              value={formData.smokingStatus || ''} 
              onChange={handleChange} 
              isEditable={isEditable} 
            />
            <Dropdown 
              label="Alcohol Consumption" 
              name="alcoholConsumption" 
              options={alcoholConsumption} 
              required 
              value={formData.alcoholConsumption || ''} 
              onChange={handleChange} 
              isEditable={isEditable} 
            />
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="substanceUse">Substance Use<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input" 
                type="text" 
                name="substanceUse" 
                id="substanceUse" 
                required 
                value={formData.substanceUse || ''} 
                onChange={handleChange} 
                placeholder="E.g., Recreational Drugs" 
                readOnly={!isEditable} 
              />
            </div>
            {/* <div className="form-buttons">
              <button 
                  type="button" 
                  className={`cancel-button lifestyle-cancel-button ${!isEditable ? 'disabled-button' : ''}`} 
                  onClick={() => isEditable && clearSection(lifestyleRef)}
                  disabled={!isEditable}
              >
                  CANCEL
              </button>
              <button 
                  type="button" 
                  className={`save-button lifestyle-save-button ${!isEditable ? 'disabled-button' : ''}`} 
                  onClick={handleSave(lifestyleRef)}
                  disabled={!isEditable}
              >
                  SAVE
              </button>
            </div> */}
          </div>

          {/* Clinical Data Section */}
          <div className="form-section" ref={clinicalDataRef}>
            <div className="section-title-container">
              <h3 className="section-title">Clinical Data</h3>
            </div>
            <div className="form-group">
              <label 
                className="form-label" 
                htmlFor="recentLabResults">Recent Lab Results<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input" 
                type="text" 
                name="recentLabResults" 
                id="recentLabResults" 
                required value={formData.recentLabResults || ''} 
                onChange={handleChange} 
                placeholder="E.g., Blood tests, Cholesterol levels" 
                readOnly={!isEditable} 
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="diagnosticReports">
                Diagnostic Reports<span className="required-field">*</span>:
              </label>
              
              {/* Group both existing file link and the new file upload text */}
              <div className={`file-container ${!isEditable ? 'read-only' : ''}`}>
                {formData.diagnosticReportsSignedUrl && (
                  <a href={formData.diagnosticReportsSignedUrl} className='file-link' target="_blank" rel="noopener noreferrer">
                    {getFileNameWithoutTimestamp(formData.diagnosticReportsSignedUrl)}
                  </a>
                )}
                
                {/* Editable Mode: Provide option to upload new file */}
                {isEditable && (
                <div>
                  {/* Dropzone to select a new file */}
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    {formData.diagnosticReports instanceof File ? (
                      <div className='upload-button'>
                        <p className='new-file'>New file: {formData.diagnosticReports.name}</p>
                      </div>
                    ) : (
                      <div className='upload-button'>upload file</div>
                    )}
                  </div>
                </div>
                )}
              </div>
            </div>
            <div className="form-group small-input-group">
              <label 
                className="form-label" 
                htmlFor="height">Height (cm)<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input small-input" 
                type="number" 
                name="height" 
                id="height" 
                required 
                value={formData.height || ''} 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
              <span className="unit-label">CM</span>
            </div>
            <div className="form-group small-input-group">
              <label 
                className="form-label" 
                htmlFor="weight">Weight (kg)<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input small-input" 
                type="number" 
                name="weight" 
                id="weight" 
                required 
                value={formData.weight || ''} 
                onChange={handleChange} 
                readOnly={!isEditable} 
              />
              <span className="unit-label">KG</span>
            </div>
            <div className="form-group small-input-group">
              <label 
                className="form-label" 
                htmlFor="bmi">BMI (kg/m²)<span className="required-field">*</span>:
              </label>
              <input 
                className="form-input small-input" 
                type="number" 
                name="bmi" 
                id="bmi" 
                required 
                value={formData.bmi || ''} 
                readOnly 
              />
              <span className="unit-label">kg/m²</span>
            </div>
            {/* <div className="form-buttons">
              <button 
                  type="button" 
                  className={`cancel-button clinicaldata-cancel-button ${!isEditable ? 'disabled-button' : ''}`} 
                  onClick={() => isEditable && clearSection(clinicalDataRef)}
                  disabled={!isEditable}
              >
                  CANCEL
              </button>
              <button 
                  type="button" 
                  className={`save-button clinicaldata-save-button ${!isEditable ? 'disabled-button' : ''}`} 
                  onClick={handleSave(clinicalDataRef)}
                  disabled={!isEditable}
              >
                  SAVE
              </button>
            </div> */}
          </div>

          {/* Additional Information Section */}
          <div className="form-section" ref={additionalInfoRef}>
            <div className="section-title-container">
              <h3 className="section-title">Additional Information</h3>
            </div>
            <Dropdown 
              label="Participation Availability" 
              name="participationAvailability" 
              options={participationAvailability} 
              required 
              value={formData.participationAvailability || ''} 
              onChange={handleChange} 
              isEditable={isEditable} 
            />
            <div className="form-group checkbox-group">
              <label className="form-label checkbox-label">
                <input 
                  className="form-checkbox" 
                  type="checkbox" 
                  name="agree" 
                  id="agree" 
                  required 
                  checked={formData.agree} 
                  onChange={handleChange} 
                  disabled={!isEditable} 
                />
                I agree to use my data for matching and research purposes
              </label>
            </div>
            {/* <div className="form-buttons">
              <button 
                  type="button" 
                  className={`cancel-button additionalinfo-cancel-button ${!isEditable ? 'disabled-button' : ''}`} 
                  onClick={() => isEditable && clearSection(additionalInfoRef)}
                  disabled={!isEditable}
              >
                  CANCEL
              </button>
              <button 
                  type="button" 
                  className={`save-button additionalinfo-save-button ${!isEditable ? 'disabled-button' : ''}`} 
                  onClick={handleSave(additionalInfoRef)}
                  disabled={!isEditable}
              >
                  SAVE
              </button>
            </div> */}
          </div>
          <div className="form-buttons">
            {isEditable ? (
              <>
                <button type="button" className="cancel-button" onClick={handleCancel}>Cancel</button>
                <button type="submit" className="submit-button" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </>
            ) : (
              <button type="button" className="edit-button" onClick={handleEdit}>Edit</button>
            )}
          </div>
        </form>
      </>
      )}
    </div>
  );
}

export default ProfileUpdate;
